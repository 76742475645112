module.exports = {
  siteTitle: 'Gian Marco Gioria | Digital Energy', // <title>
  manifestName: 'Spectral',
  manifestShortName: 'Personal', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/website-icon.png',
  pathPrefix: `/www/`, // This path is subpath of your hosting https://domain/portfolio
  heading: 'Gian Marco Gioria',
  subHeading: 'INDEPENDENT DIGITAL OILFIELD CONSULTANT',
  // social
  socialLinks: [
    {
      style: 'brands',
      icon: 'fa-linkedin',
      name: 'LinkedIn',
      url: 'https://www.linkedin.com/in/gian-marco-gioria-77681868',
    },
  ],
};

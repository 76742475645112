import React from 'react';
import config from '../../config';
export default function Footer() {
  return (
    <footer id="footer">
      <ul className="icons">
        {config.socialLinks.map(social => {
          const { style, icon, name, url } = social;
          return (
            <li key={name} className="list-inline-item">
                <a
                  href={url}
                  className="social-link rounded-circle text-white mr-3"
                >
                  <i className={`icon ${style} ${icon}`}></i>
                </a>
              </li>
          );
        })}
        <li>
          <a 
          className="social-link rounded-circle text-white mr-3"
          href="javascript:location='mailto:\u0067\u0069\u0061\u006e\u006d\u0061\u0072\u0063\u006f\u002e\u0067\u0069\u006f\u0072\u0069\u0061\u0040\u006f\u0075\u0074\u006c\u006f\u006f\u006b\u002e\u0063\u006f\u006d';void 0"><script type="text/javascript">document.write('\u0067\u0069\u0061\u006e\u006d\u0061\u0072\u0063\u006f\u002e\u0067\u0069\u006f\u0072\u0069\u0061\u0040\u006f\u0075\u0074\u006c\u006f\u006f\u006b\u002e\u0063\u006f\u006d')</script>
          <i className={`icon solid fa-envelope`}></i>
          </a>
     
        </li>
      </ul>
      <ul className="copyright">
        <li>
          Copyright © 2020 GMdigitalenergy Design: <a href="https://www.hobaspace.com">HobaSpace</a>
        </li>
         <li>
          <a href="/terms">Terms & Condition</a>
        </li>
      </ul>
    </footer>
  );
}
